<template>
  <b-modal
    id="activate-user"
    title="User Activation"
    ok-title="Save"
    ref="modal"
    @close="handleCancel"
    @cancel="handleCancel"
    @ok="handleOk"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
  >
    <br />
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />
    <div>
      <span v-if="isActive">
        Are you sure you want to
        <b>deactivate</b>
        user
        <b>{{ name }}?</b>
      </span>
      <span v-else>
        Are you sure you want to
        <b>activate</b>
        user
        <b>{{ name }}?</b>
      </span>
    </div>
    <br />
  </b-modal>
</template>

<script>
  // DAO
  import userDAO from "../../database/users";

  // Util
  import dateUtil from "../../utils/dateUtil";

  // Others
  import { bus } from "../../main";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";

  export default {
    name: "activate-user",
    components: {
      Loading,
    },
    data() {
      return {
        selUser: {},
        isActive: true,

        // Check for loader
        isLoading: false,
        loggedUser: this.$store.getters["auth/loggedUser"],
      };
    },
    computed: {
      disableConfirmButtons() {
        return this.isLoading;
      },
      name() {
        return this.selUser.firstName + " " + this.selUser.lastName;
      },
    },
    mounted() {
      bus.$on("onUpdateSelUser", (selUser) => {
        this.selUser = Object.assign({}, selUser);
        this.isActive = selUser.isActive;
      });
    },
    methods: {
      resetStatus() {
        this.selUser.isActive = this.isActive;
        this.$store.commit("SET_CURR_USER", this.selUser);
      },
      async handleCancel() {
        this.resetStatus();
        bus.$emit("onCloseActivateUser", this.selUser);
        this.isLoading = false;
      },
      async handleOk() {
        try {
          // show loading indicator
          this.isLoading = true;

          // update status
          this.selUser.isActive = !this.isActive;
          // Update Timestamps
          this.selUser.updatedBy = this.loggedUser;
          this.selUser.dateUpdated = dateUtil.getCurrentTimestamp();

          let result = await userDAO.updateUser(this.selUser);

          if (result.isSuccess) {
            let activationLabel = this.selUser.isActive
              ? "activated"
              : "deactivated";
            this.$toast.success(
              `User ${this.name} account is now ${activationLabel}!`,
              "Success"
            );
            this.$refs.modal.hide();
            bus.$emit("onCloseActivateUser", this.selUser);
          } else {
            let errorLabel = this.selUser.isActive
              ? "activating"
              : "deactivating";
            this.$toast.error(
              `There is an error ${errorLabel} user ${this.name}. Please try again.`,
              "Error"
            );
          }
        } catch (error) {
          console.error(error);
          let errorLabel = this.selUser.isActive
            ? "activating"
            : "deactivating";
          this.$toast.error(
            `There is an error ${errorLabel} user ${this.name}. Please try again.`,
            "Error"
          );
        }

        // hide loading indicator
        this.isLoading = false;
      },
    },
    beforeDestroy() {
      bus.$off("onUpdateSelUser");
    },
  };
</script>
