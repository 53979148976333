<template>
  <b-modal
    id="edit-user"
    size="lg"
    title="Edit User"
    ref="modal"
    @ok="handleOk"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <b-tabs>
        <b-tab title="Primary Details">
          <div class="form-row mt-4">
            <div class="col-md-4">
              <b-form-group
                label="First Name"
                label-for="firstName"
                description
              >
                <b-form-input
                  name="First Name"
                  type="text"
                  v-model="form.firstName"
                  v-validate="{
                    required: true,
                    regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
                  }"
                  placeholder="First Name"
                  maxlength="25"
                />
                <span v-show="errors.has('First Name')" class="help-block">
                  {{ errors.first('First Name') }}
                </span>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Middle Name"
                label-for="middleName"
                description
              >
                <b-form-input
                  name="Middle Name"
                  type="text"
                  v-model="form.middleName"
                  v-validate="{
                    required: false,
                    regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
                  }"
                  placeholder="Middle Name"
                  maxlength="25"
                />
                <span v-show="errors.has('Middle Name')" class="help-block">
                  {{ errors.first('Middle Name') }}
                </span>
              </b-form-group>
            </div>

            <div class="col-md-4">
              <b-form-group label="Last Name" label-for="lastName" description>
                <b-form-input
                  name="Last Name"
                  type="text"
                  v-model="form.lastName"
                  v-validate="{
                    required: true,
                    regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
                  }"
                  placeholder="Last Name"
                  maxlength="25"
                />
                <span v-show="errors.has('Last Name')" class="help-block">
                  {{ errors.first('Last Name') }}
                </span>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group
                label="Job Position"
                label-for="position"
                description
              >
                <b-form-input
                  name="Position"
                  type="text"
                  v-model="form.position"
                  v-validate="'required'"
                />
                <span v-show="errors.has('Position')" class="help-block">
                  {{ errors.first('Position') }}
                </span>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Gender" label-for="gender">
                <b-form-select
                  id="gender"
                  name="Gender"
                  v-model="form.gender"
                  :options="options"
                  v-validate="'required'"
                />
                <span v-show="errors.has('Gender')" class="help-block">
                  {{ errors.first('Gender') }}
                </span>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <b-form-group
                label="Address"
                label-for="address"
                description="* Please enter the complete address"
              >
                <b-form-input
                  id="address"
                  name="Address"
                  type="text"
                  v-model="form.address"
                  v-validate="{
                    required: true,
                    regex: /^(?=.*[A-Za-z])[A-Za-z\d\s(),.]+$/,
                  }"
                  placeholder="Address"
                />
                <span v-show="errors.has('Address')" class="help-block">
                  {{ errors.first('Address') }}
                </span>
              </b-form-group>
            </div>
          </div>
        </b-tab>

        <b-tab title="Account">
          <div class="form-row mt-4">
            <div class="col-md-6">
              <b-form-group label="Email" label-for="email">
                <base-input readonly="true" id="email" v-model="form.email" />
              </b-form-group>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <base-button type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button type="primary" class="text-dark" @click="ok()">
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// DAO
import userDAO from '../../database/users'

// Util
import dateUtil from '../../utils/dateUtil'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'edit-user',
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        firstName: '',
        middleName: '',
        lastName: '',
        position: '',
        gender: null,
        address: '',
        email: '',
        password: '',
        isActive: true,
        createdBy: '',
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: '',
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },
      options: [
        { value: 'null', text: ' - Please select - ' },
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
      ],

      showPassword: false,
      showConfirmPassword: false,

      // Check for loader
      isLoading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
    name() {
      return this.form.firstName + ' ' + this.form.lastName
    },
  },
  methods: {
    async handleOk(evt) {
      evt.preventDefault()

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          'Please address the field/s with invalid input.',
          'Warning',
        )
        this.isLoading = false
        return
      }

      await this.handleSubmit()
    },

    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true

        // Update Timestamps
        this.form.updatedBy = this.loggedUser
        this.form.dateUpdated = dateUtil.getCurrentTimestamp()

        let result = await userDAO.updateUser(this.form)

        if (result.isSuccess) {
          this.$toast.success(this.form.firstName + ' ' + this.form.lastName + ' was updated.', 'Success')
          this.$refs.modal.hide()
          bus.$emit('onCloseEditUser', this.form)
        } else {
          this.$toast.error(
            'There is an error updating user. Please try again.',
            'Error',
          )
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(
          'There is an error updating user. Please try again.',
          'Error',
        )
      }

      // hide loading indicator
      this.isLoading = false
    },

    onReset() {
      let user = this.$store.getters['user/currUser']
      let userJSON = JSON.stringify(user)
      this.form = JSON.parse(userJSON)

      // reset validation
      this.isLoading = false
      this.$validator.reset()
      this.errors.clear()
    },
  },
}
</script>
