import { db } from "../config/firebase";

// Util
import formatUtil from '../utils/formatUtil';

function generateQuery(filter) {
  let query = db.collection("contactForms").orderBy("dateCreated", "desc");

  if (filter.fromTimestamp && filter.fromTimestamp > 0) {
    query = query.where("dateCreated", ">=", filter.fromTimestamp);
  }
  if (filter.toTimestamp && filter.toTimestamp > 0) {
    query = query.where("dateCreated", "<=", filter.toTimestamp);
  }

  return query;
}

async function retrieveFilteredRequests(filter) {
  let query = generateQuery(filter);

  const querySnapshot = await query.get();

  let results = [];
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    results.push({
      id: doc.id,
      ...data,
    });
  });

  return results;
}

async function markRequestAsDone(form, id) {
  // cleanup before saving
  form = formatUtil.cleanupFields(form);

  const docRef = db.collection("contactForms").doc(id);
  return await docRef.set({ ...form, isOnGoing: false }, { merge: true });
}

export default { retrieveFilteredRequests, markRequestAsDone };
