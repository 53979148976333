import { db } from '../config/firebase';

// Util
import formatUtil from '../utils/formatUtil';

async function retrieveAll() {
  const querySnapshot = await db.collection("projects").get();

  let results = [];
  querySnapshot.forEach(doc => {
    results.push({
      id: doc.id,
      ...doc.data()
    });
  });

  return results;
}

async function createProject(project) {
  let query = db.collection("projects");
  return await query.add(project);
}

async function updateProject(project, id) {
  // cleanup before saving
  project = formatUtil.cleanupFields(project);

  let query = db.collection("projects").doc(id);
  return await query.set(project, { merge: true });
}

export default {
  retrieveAll,
  createProject,
  updateProject,
};