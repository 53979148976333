import { db } from "../config/firebase";

// Util
import formatUtil from '../utils/formatUtil';

async function retrieveAll() {
  let query = db.collection("salesContacts").orderBy("name", "desc");

  const querySnapshot = await query.get();

  let results = [];
  querySnapshot.forEach((doc) => {
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return results;
}

async function updateSalesContacts(contact) {
  // cleanup before saving
  contact = formatUtil.cleanupFields(contact);

  let query = db.collection("salesContacts").doc(contact.id);
  return await query.set(contact);
}

export default {
  retrieveAll,
  updateSalesContacts,
};
