<template>
  <b-modal id="mark-request-as-done" title="Mark As Done" ok-title="Save" ref="modal" @close="handleCancel"
    @cancel="handleCancel" @ok="handleDone" @show="onReset" :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
    <br />
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    <div>
      <span>
        Proceed marking
        <b>Request {{ selRequest.requestId }}</b>
        as DONE?
      </span>
    </div>
    <br />
    <template #modal-footer="{ ok, cancel }">
      <base-button type="primary" @click="ok()" :disabled="isLoading">
        Save
      </base-button>
      <base-button type="link" @click="cancel()" :disabled="isLoading">
        Close
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// Utils
import dateUtil from '../../utils/dateUtil'

// API and DAO
import requestDAO from '../../database/contactForms'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'mark-request-as-done',
  components: {
    Loading,
  },
  data() {
    return {
      selRequest: {},

      // Check for loader
      isLoading: false,

      loggedUser: this.$store.getters['auth/loggedUser'],
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
  },

  methods: {
    async handleDone() {
      try {
        this.isLoading = true
        await requestDAO.markRequestAsDone(
          this.selRequest,
          this.selRequest.id,
        )
        this.$toast.success('Request Marked as Done', 'Success!');

        // Update Timestamps
        this.selRequest.updatedBy = this.loggedUser
        this.selRequest.dateUpdated = dateUtil.getCurrentTimestamp()

        this.$refs.modal.hide()
        bus.$emit('onCloseSelRequest', this.selRequest)

      } catch (error) {
        console.error(error)
      }
      this.isLoading = false

    },

    handleCancel() {
      this.$refs.modal.hide()
    },
    onReset() {
      this.selRequest = this.$store.getters['contactForm/currRequest']
      this.items = this.selRequest.items
    },
  },

}
</script>
