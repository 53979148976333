export default {

    cleanupFields(doc) {
        let cleanedDispatch = Object.assign({}, doc);

        delete cleanedDispatch['_showDetails'];

        return cleanedDispatch;
    }

}