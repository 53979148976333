<template>
  <b-modal
    id="edit-sales-contacts"
    size="sm"
    title="Edit Sales Contacts"
    ref="modal"
    @ok="handleOk"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <div class="form-row mt-4">
        <div class="col-md-12">
          <b-form-group label="Name" label-for="name" description>
            <b-form-input
              name="Name"
              type="text"
              v-model="form.name"
              v-validate="{
                required: true,
                regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
              }"
              placeholder="Name"
              maxlength="25"
            />
            <span v-show="errors.has('Name')" class="help-block">
              {{ errors.first('Name') }}
            </span>
          </b-form-group>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <b-form-group
            label="Contact Number"
            label-for="contactNumber"
            description
          >
            <b-form-input
              name="Contact Number"
              type="number"
              v-model="form.contactNumber"
              v-validate="{
                required: true,

              }"
            />
            <span v-show="errors.has('Contact Number')" class="help-block">
              {{ errors.first('Contact Number') }}
            </span>
          </b-form-group>
        </div>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <base-button type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button type="primary" class="text-dark" @click="ok()">
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// DAO
import salesContactDAO from '../../database/salesContacts'

// Util
import dateUtil from '../../utils/dateUtil'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'edit-sales-contacts',
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        name: '',
        contactNumber: '',
        createdBy: '',
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: '',
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },

      // Check for loader
      isLoading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
  },
  methods: {
    async handleOk(evt) {
      evt.preventDefault()

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          'Please address the field/s with invalid input.',
          'Warning',
        )
        this.isLoading = false
        return
      }

      await this.handleSubmit()
    },

    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true

        // Update Timestamps
        this.form.updatedBy = this.loggedUser
        this.form.dateUpdated = dateUtil.getCurrentTimestamp()

        await salesContactDAO.updateSalesContacts(this.form)

        this.$toast.success(this.form.name +' was updated.', 'Success')
        this.$refs.modal.hide()
        bus.$emit('onCloseEditSalesContacts', this.form)
      } catch (error) {
        console.error(error)
        this.$toast.error(
          'There is an error updating sales contact. Please try again.',
          'Error',
        )
      }

      // hide loading indicator
      this.isLoading = false
    },

    onReset() {
      let salesContacts = this.$store.getters['salescontacts/currSalesContact']
      let salesContactsJSON = JSON.stringify(salesContacts)
      this.form = JSON.parse(salesContactsJSON)

      // reset validation
      this.isLoading = false
      this.$validator.reset()
      this.errors.clear()
    },
  },
}
</script>
