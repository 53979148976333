<template>
  <div>
    <b-modal
      v-model="showModal"
      @hidden="closeModal"
      no-close-on-backdrop
      no-close-on-esc
      hide-backdrop
    >
      <template #modal-title>
        <h2>Forgot Password</h2>
      </template>

      <template #instructions>
        <h5></h5>
      </template>

      <template #default>
        <!-- Forgot password form -->

        <form @submit.prevent="submitForm">
          <div>
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :loader="loader"
              :color="color"
            />
            <b-form-group label="Email" label-for="email">
              <base-input
                placeholder="email@example.com"
                addon-left-icon="ni ni-email-83"
                v-model="email"
                :error="emailError"
                id="email"
                @input="clearError"
              />
            </b-form-group>
            <div
              v-if="registrationError"
              class="text-danger"
              style="font-size: 10px; text-align: center;"
            >
              {{ registrationError }}
            </div>
          </div>
        </form>
      </template>

      <template #modal-footer>
        <div>
          <base-button
            type="submit"
            class="text-dark bg-primary"
            @click="validateForm"
            :disabled="isLoading"
          >
            Reset Password
          </base-button>
          <base-button
            type="secondary"
            class="text-dark"
            @click="closeModal"
            :disabled="isLoading"
          >
            Close
          </base-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { bus } from '../main'
import { auth } from '../config/firebase'

export default {
  data() {
    return {
      showModal: false,
      email: '',
      isLoading: false,
      registrationError: '', // Error message for email not registered
      loader: false,
      color: 'red',
    }
  },
  computed: {
    emailError() {
      if (!this.email) {
        return 'Email is required'
      } else if (!this.isEmailValid(this.email)) {
        return 'Email format is invalid'
      } else {
        return ''
      }
    },
  },
  methods: {
    closeModal() {
      this.showModal = false // Set showModal to false to close the modal
      this.email = '' // Reset the email field
      this.registrationError = '' // Clear the registration error message
    },
    validateForm() {
      if (!this.emailError) {
        this.submitForm()
      }
    },
    submitForm() {
      const email = this.email
      this.isLoading = true

      // Check if the email is registered in the database
      auth
        .fetchSignInMethodsForEmail(email)
        .then((providers) => {
          if (providers.length > 0) {
            // Email is registered, send password reset email
            auth
              .sendPasswordResetEmail(email)
              .then(() => {
                console.log('Password reset email sent to:', email)
                this.$toast.success('Email Sent Successfully.', 'Success')
                this.closeModal()
              })
              .catch((error) => {
                console.error('Error sending password reset email:', error)
              })
              .finally(() => {
                this.isLoading = false
              })
          } else {
            // Email is not registered
            this.registrationError = 'Email is not registered'
            console.log('Email is not registered:', email)
            this.isLoading = false
          }
        })
        .catch((error) => {
          console.error('Error checking email registration:', error)
          this.isLoading = false
        })
    },
    clearError() {
      this.registrationError = ''
    },
    isEmailValid(email) {
      // Email format validation logic
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailRegex.test(email)
    },
  },
  created() {
    bus.$on('openForgotPasswordModal', () => {
      this.showModal = true
    })
  },
}
</script>
