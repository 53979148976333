<template>
  <div class="container-fluid">
    <b-card>
      <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

      <template v-slot:header>
        <i class="fa fa-phone text-primary"></i>
        &nbsp;
        <strong>Sales Contacts</strong>
        &nbsp;
        <small>Lists of Sales Contact Number</small>
      </template>

      <b-card-text>
        <b-row class="mb-3">
          <b-col sm="6" md="4" offset-md="8" class="text-md-right">
            <base-dropdown position="right">
              <base-button slot="title" type="primary" class="dropdown-toggle">
                Show {{ perPage }} / Page
              </base-button>
              <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
                {{ option }}
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
        </b-row>

        <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage"
          :per-page="perPage" responsive show-empty style="min-height: 380px;">
          <template v-slot:cell(dateUpdated)="{ item }">
            {{ item.dateUpdated | dateAndTimeFormatter }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button title="Edit Contact" size="sm" v-b-modal.edit-sales-contacts
              v-b-tooltip.hover.top="'Update Sales Contact Details'" variant="info"
              @click.stop="updateSelSalesContact(row.item)">
              <b-icon-pen />
            </b-button>
          </template>
        </b-table>
      </b-card-text>

      <template v-slot:footer>
        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="totalDisplay">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
    </b-card>

    <!-- Modals -->
    <EditSalesContact />
  </div>
</template>

<script>
//Components
import EditSalesContact from './salesContacts/EditSalesContact'

//DAO
import salesContactDAO from '../database/salesContacts'

//Others
import { bus } from '../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'sales-contacts',
  components: {
    EditSalesContact,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        { key: 'name', label: 'Name' },
        { key: 'contactNumber', label: 'Contact Number' },
        { key: 'dateUpdated', label: 'Date Updated' },
        { key: 'actions', label: 'Actions' },
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
    }
  },

  computed: {
    exportData() {
      let allContactSalesArr = []
      this.items.forEach((item) => {
        let salesContact = Object.assign({}, item)
        allContactSalesArr.push(salesContact)
      })
      return allContactSalesArr
    },
  },

  async mounted() {
    // pre-load salesContact
    await this.getAllSalesContacts()
    bus.$on('onCloseEditSalesContacts', () => this.getAllSalesContacts())
  },

  methods: {
    async getAllSalesContacts() {
      try {
        this.isLoading = true
        const salesContact = await salesContactDAO.retrieveAll()
        this.items = salesContact.map((salesContact) => {
          return {
            ...salesContact,
          }
        })
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.error(error)
      }
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    updateSelSalesContact(item) {
      this.selSalesContact = item
      this.$store.commit('salescontacts/SET_CURR_SALESCONTACT', item)
      bus.$emit('onUpdateSelSalesContact', item)
    },
  },

  beforeDestroy() {
    bus.$off('onCloseEditSalesContact')
  },
}
</script>
