<template>
  <b-modal
    id="add-user"
    size="lg"
    title="Add User"
    ref="modal"
    @ok="handleOk"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
  >
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <b-tabs>
        <b-tab title="Primary Details">
          <div class="form-row mt-4">
            <div class="col-md-4">
              <b-form-group
                label="First Name"
                label-for="firstName"
                description
              >
                <b-form-input
                  name="First Name"
                  type="text"
                  v-model="form.firstName"
                  v-validate="{
                    required: true,
                    regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
                  }"
                  placeholder="First Name"
                  maxlength="25"
                />
                <span v-show="errors.has('First Name')" class="help-block">
                  {{ errors.first('First Name') }}
                </span>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Middle Name"
                label-for="middleName"
                description
              >
                <b-form-input
                  name="Middle Name"
                  type="text"
                  v-model="form.middleName"
                  v-validate="{
                    required: false,
                    regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
                  }"
                  placeholder="Middle Name"
                  maxlength="25"
                />
                <span v-show="errors.has('Middle Name')" class="help-block">
                  {{ errors.first('Middle Name') }}
                </span>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Last Name" label-for="lastName" description>
                <b-form-input
                  name="Last Name"
                  type="text"
                  v-model="form.lastName"
                  v-validate="{
                    required: true,
                    regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
                  }"
                  placeholder="Last Name"
                  maxlength="25"
                />
                <span v-show="errors.has('Last Name')" class="help-block">
                  {{ errors.first('Last Name') }}
                </span>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <b-form-group
                label="Job Position"
                label-for="position"
                description
              >
                <b-form-input
                  name="Position"
                  type="text"
                  placeholder="Job Position"
                  v-model="form.position"
                  v-validate="'required'"
                />
                <span v-show="errors.has('Position')" class="help-block">
                  {{ errors.first('Position') }}
                </span>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group label="Gender" label-for="gender">
                <b-form-select
                  id="gender"
                  name="Gender"
                  v-model="form.gender"
                  :options="options"
                  v-validate="'required'"
                />
                <span v-show="errors.has('Gender')" class="help-block">
                  {{ errors.first('Gender') }}
                </span>
              </b-form-group>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <b-form-group
                label="Address"
                label-for="address"
                description="* Please enter the complete address"
              >
                <b-form-input
                  id="address"
                  name="Address"
                  type="text"
                  v-model="form.address"
                  v-validate="{
                    required: true,
                    regex: /^(?=.*[A-Za-z])[A-Za-z\d\s(),.]+$/,
                  }"
                  placeholder="Address"
                />
                <span v-show="errors.has('Address')" class="help-block">
                  {{ errors.first('Address') }}
                </span>
              </b-form-group>
            </div>
          </div>
        </b-tab>

        <b-tab title="Account">
          <div class="form-row mt-4">
            <div class="col-md-6">
              <b-form-group
                label="Email Address:"
                label-for="email"
                description="This will be used as the username of this user's account"
              >
                <b-form-input
                  name="Email Address"
                  type="email"
                  v-model="form.email"
                  v-validate="{
                    required: true,
                    regex: /^[\w.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  }"
                  placeholder="Email Address"
                />
                <span v-show="errors.has('Email Address')" class="help-block">
                  {{ errors.first('Email Address') }}
                </span>
              </b-form-group>
            </div>
          </div>

          <div class="form-row">
            <div class="col-md-6">
              <b-form-group label="Password" label-for="Password">
                <div class="input-group input-group-lg">
                  <base-input
                    class="col-9 p-0 mr-2"
                    name="Password"
                    id="Password"
                    :type="passwordFieldType"
                    v-model="form.password"
                    autocomplete="new-password"
                    v-validate="{
                      required: true,
                      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$%*?&_\-+|!])[A-Za-z\d@$%*?&_\-+|!]{8,}$/,
                    }"
                  />
                  <button
                    class="btn btn-outline-secondary button-eye"
                    type="button"
                    @click="togglePasswordVisibility"
                  >
                    <i
                      class="fa"
                      :class="
                        passwordFieldType === 'password'
                          ? 'fa-eye'
                          : 'fa-eye-slash'
                      "
                    ></i>
                  </button>
                </div>
                <span v-show="errors.has('Password')" class="help-block">
                  {{ passwordErrorMsg }}
                </span>
              </b-form-group>
            </div>

            <div class="col-md-6">
              <b-form-group
                label="Confirm Password"
                label-for="Confirm Password"
              >
                <div class="input-group input-group-lg">
                  <base-input
                    class="col-9 p-0 mr-2"
                    name="Confirm Password"
                    id="Confirm Password"
                    :type="confirmPasswordFieldType"
                    v-model="form.confirmPassword"
                    autocomplete="confirm-password"
                    v-validate="{
                      required: true,
                      regex: passwordRegex,
                    }"
                  />
                  <button
                    class="btn btn-outline-secondary button-eye"
                    type="button"
                    @click="toggleConfirmPasswordVisibility"
                  >
                    <i
                      class="fa"
                      :class="
                        confirmPasswordFieldType === 'password'
                          ? 'fa-eye'
                          : 'fa-eye-slash'
                      "
                    ></i>
                  </button>
                </div>
                <span
                  v-show="errors.has('Confirm Password')"
                  class="help-block"
                >
                  {{ errors.first('Confirm Password') }}
                </span>
              </b-form-group>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <base-button
        :disabled="isLoading"
        type="secondary"
        class="text-dark"
        @click="cancel()"
      >
        Close
      </base-button>
      <base-button
        :disabled="isLoading"
        type="primary"
        class="text-dark"
        @click="ok()"
      >
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
// DAO
import userDAO from '../../database/users'

// Util
import dateUtil from '../../utils/dateUtil'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import _ from 'lodash'

export default {
  watch: {},
  name: 'add-user',
  components: {
    Loading,
  },
  props: {
    allUsersObj: {
      type: Object,
    },
  },
  data() {
    return {
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      form: {
        firstName: '',
        middleName: '',
        lastName: '',
        position: '',
        gender: null,
        address: '',
        email: '',
        password: '',
        isActive: true,
        createdBy: '',
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: '',
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },

      options: [
        { value: 'null', text: ' - Please select - ' },
        { value: 'Male', text: 'Male' },
        { value: 'Female', text: 'Female' },
      ],

      showPassword: false,
      showConfirmPassword: false,

      // Check for loader
      isLoading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
    passwordRegex() {
      return /[A-Za-z0-9<>().,"'=:;@$%*?!&_\-+|]{8,}/
    },
    passwordErrorMsg() {
      return 'Password must have at least eight (8) characters and should contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character'
    },
    name() {
      return this.form.firstName + ' ' + this.form.lastName
    },
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
    },

    async handleOk(evt) {
      evt.preventDefault()

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          'Please address the field/s with invalid input.',
          'Warning',
        )
        this.isLoading = false
        return
      }

      if (this.form.password !== this.form.confirmPassword) {
        this.$toast.error('Password does not match.', 'Error')
        return
      }

      const existingUser = await userDAO.getUserByEmail(this.form.email)
      if (!_.isEmpty(existingUser)) {
        this.$toast.warning(
          'User already exists with your inputted email.',
          'warning',
        )
        this.isLoading = false
        return
      }

      await this.handleSubmit()
    },

    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true

        // Update Timestamps
        this.form.createdBy = this.loggedUser
        this.form.dateCreated = dateUtil.getCurrentTimestamp()

        // Save the user details to the database
        let result = await userDAO.createUser(this.form)

        if (result.isSuccess) {
          this.$toast.success('User was added.', 'Success')
          this.$refs.modal.hide()
          bus.$emit('onCloseAddUser', this.form)
        } else {
          this.$toast.error(
            'There is an error adding user. Please try again.',
            'Error',
          )
        }
      } catch (error) {
        console.error(error)
        this.$toast.error(
          'There is an error adding user. Please try again.',
          'Error',
        )
      }

      // hide loading indicator
      this.isLoading = false
    },

    onReset() {
      this.form = {
        firstName: '',
        middleName: '',
        lastName: '',
        position: '',
        gender: null,
        address: '',
        email: '',
        password: '',
        isActive: true,
        createdBy: '',
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: '',
        dateUpdated: dateUtil.getCurrentTimestamp(),
      }

      // reset validation
      this.isLoading = false
      this.$validator.reset()
      this.errors.clear()
    },
  },
}
</script>

<style scoped>
.button-eye {
  height: 40px;
  width: 40px;
  padding: 0;
}
</style>
