<template>
  <div class="container-fluid">
    <b-card>
      <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

      <template v-slot:header>
        <i class="fa fa-user text-primary"></i>
        &nbsp;
        <strong>Users</strong>
        &nbsp;
        <small>List of users with access to CMS</small>
      </template>

      <b-card-text>
        <b-row class="mb-3">
          <b-col sm="6" md="3">
            <b-dropdown text=" Select Actions " variant="primary" slot="append">
              <b-dropdown-item v-b-modal.add-user>
                <i class="fa fa-plus"></i>
                Add User
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="6" md="4" offset-md="5" class="text-md-right">
            <base-dropdown position="right">
              <base-button slot="title" type="primary" class="dropdown-toggle">
                Show {{ perPage }} / Page
              </base-button>
              <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
                {{ option }}
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
        </b-row>

        <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage"
          :per-page="perPage" responsive show-empty style="min-height: 380px">
          <template v-slot:cell(dateCreated)="{ item }">
            {{ item.dateCreated | dateAndTimeFormatter }}
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" v-b-modal.edit-user v-b-tooltip.hover.top="'Edit Details'" variant="primary"
              @click.stop="updateSelUser(row.item)" class="mr-1">
              <i class="fas fa-pen"></i>
            </b-button>
          </template>

          <template v-slot:cell(name)="row">
            {{ getName(row.item) }}
          </template>

          <template v-slot:cell(status)="row">
            <div>
              <a @click="updateSelUser(row.item)">
                <b-form-checkbox name="status" v-b-modal.activate-user v-model="row.item.isActive" switch>
                  {{ row.item.isActive ? "Active" : "Inactive" }}
                </b-form-checkbox>
              </a>
            </div>
          </template>
        </b-table>
      </b-card-text>

      <template v-slot:footer>
        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="totalDisplay">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
    </b-card>

    <!-- Modals -->
    <AddUser />
    <EditUser />
    <ActivateUser />
  </div>
</template>

<script>
//components
import AddUser from "./user/AddUser";
import EditUser from "./user/EditUser";
import ActivateUser from "./user/ActivateUser.vue";

// DAO
import userDAO from "../database/users";

//others
import { bus } from "../main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "users",
  components: {
    AddUser,
    EditUser,
    ActivateUser,
    Loading,
  },
  data() {
    return {
      selUser: {},
      allUsersObj: {},
      items: [],
      fields: [
        { key: "name", label: "Name", sortable: true },
        { key: "position", label: "Position" },
        { key: "address", label: "Address" },
        { key: "email", label: "Email" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, 50, 75, 100],

      isLoading: false,
    };
  },
  computed: {
    exportData() {
      let allUsersArr = [];
      this.items.forEach((item) => {
        let user = Object.assign({}, item);
        user.isActive = item.isActive ? "Active" : "Inactive";
        allUsersArr.push(user);
      });
      return allUsersArr;
    },
  },

  async mounted() {

    let loggedUser = this.$store.getters['auth/loggedUser'];
    if (loggedUser !== 'support@tawitech.ph') {
      this.$router.push('/login')
    }

    // pre-load users
    await this.getAllUsers();

    bus.$on("onCloseAddUser", () => this.getAllUsers());
    bus.$on("onCloseEditUser", () => this.getAllUsers());
    bus.$on("onCloseActivateUser", () => this.getAllUsers());
  },

  methods: {
    async getAllUsers() {
      try {
        this.isLoading = true;
        this.allUsersObj = await userDAO.retrieveAll();
        this.items = Object.values(this.allUsersObj);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },

    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    getName(item) {
      let name = "";

      name += item.firstName + " ";

      if (item.middleName) {
        name += item.middleName + " ";
      }

      name += item.lastName;

      return name;
    },
    updateSelUser(item) {
      this.selUser = item;
      this.$store.commit("user/SET_CURR_USER", item);
      bus.$emit("onUpdateSelUser", item);
    },
  },

  beforeDestroy() {
    bus.$off("onCloseAddUser");
    bus.$off("onCloseEditUser");
    bus.$off("onCloseActivateUser");
  },
};
</script>

<style scoped>
.bg-button {
  background: none !important;
  border: none !important;
}
</style>
