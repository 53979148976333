<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <img src="img/brand/brand.png" class="img-fluid pb-2" width="60%" />
          </div>
          <form role="form" @keyup.enter="login">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="form.email"
              autocomplete="email"
            ></base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="form.password"
              v-validate="{
                required: true,
              }"
              autocomplete="current-password"
            ></base-input>

            <div class="text-center">
              <base-button
                type="primary"
                block
                class="my-4"
                @click="login"
                :disabled="isAuthenticating"
              >
                <span v-if="isAuthenticating">
                  <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else>Sign in</span>
              </base-button>
            </div>
            <div class="text-center">
              <b-link @click="openForgotPasswordModal">Forgot password?</b-link>
            </div>

            <base-checkbox hidden v-model="rememberMe">
              <span class="text-muted">Remember me</span>
            </base-checkbox>

            <base-alert class="mt-3" v-if="error" type="danger">
              <span class="alert-inner--text">{{ error }}</span>
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true" @click="error = ''">&times;</span>
              </button>
            </base-alert>
          </form>
        </div>
      </div>
      <reset-password />
    </div>
  </div>
</template>

<script>
import { bus } from '../main'
import { auth } from '../config/firebase'
import ResetPassword from './Reset.vue'
import usersDAO from '../database/users'

export default {
  name: 'login',
  components: { ResetPassword },
  data() {
    return {
      isAuthenticating: false,
      rememberMe: true,
      form: {
        email: '',
        password: '',
      },
      showModal: false,
      error: '',
    }
  },
  watch: {
    rememberMe(v) {
      if (v) {
        const { email } = this.form
        const userCredentials = { email }
        localStorage.setItem('userCredentials', JSON.stringify(userCredentials))
      } else {
        localStorage.removeItem('userCredentials')
      }
    },
    form: {
      handler(v) {
        if (this.rememberMe) {
          const { email } = v
          const userCredentials = { email }
          localStorage.setItem(
            'userCredentials',
            JSON.stringify(userCredentials),
          )
        } else {
          localStorage.removeItem('userCredentials')
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.form = Object.assign(
      {},
      JSON.parse(localStorage.getItem('userCredentials')),
    )

    if (this.form.email) {
      this.rememberMe = true
    }
  },
  methods: {
    async login() {
      if (!this.isAuthenticating) {
        // Check if not already authenticating
        if (this.form.email && this.form.password) {
          // Check if email and password are provided
          this.isAuthenticating = true
          auth
            .signInWithEmailAndPassword(this.form.email, this.form.password)
            .then(async () => {
              let getCurrentUser = this.form.email
              let admin = 'support@tawitech.ph'

              // Find user by email in the user database
              let matchedUser = await usersDAO.getUserByEmail(getCurrentUser)

              if (matchedUser) {
                if (getCurrentUser === admin) {
                  this.$router.push('/users')
                } else if (!matchedUser.isActive) {
                  this.error =
                    'Your account does not have access. Please contact support.'
                  this.isAuthenticating = false
                  auth.signOut()
                } else {
                  this.$router.push('/quote-requests')
                }
              } else {
                this.error = 'Invalid email or password.'
                this.isAuthenticating = false
                auth.signOut()
              }
            })
            .catch((error) => {
              console.error(error)
              this.isAuthenticating = false
              this.error = error.message
            })
        } else {
          this.error = 'Please provide email and password'
        }
      }
    },
    openForgotPasswordModal() {
      // Emit an event to open the forgot password modal
      bus.$emit('openForgotPasswordModal')
    },
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
  },
}
</script>
<style></style>
