<template>
  <b-modal id="add-project" size="lg" title="Add Project" ref="modal" @ok="handleOk" @show="onReset"
    :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <div class="form-row mt-4">
        <div class="col-md-6">
          <b-form-group label="Title" label-for="title" description>
            <b-form-input name="Title" type="text" v-model="form.title" v-validate="'required'" placeholder="Title Name"
              maxlength="25" />
            <span v-show="errors.has('Title')" class="help-block">
              {{ errors.first("Title") }}
            </span>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Client Name" label-for="clientName" description>
            <b-form-input name="Client Name" type="text" v-model="form.clientName" v-validate="'required'"
              placeholder="Client Name" maxlength="25" />
            <span v-show="errors.has('Client Name')" class="help-block">
              {{ errors.first("Client Name") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <b-form-group label="Scope of works" label-for="scopeOfWorks" description>
            <b-form-input name="Scope of works" type="text" v-model="form.scopeOfWorks" v-validate="'required'"
              placeholder="Scope of works" maxlength="100" />
            <span v-show="errors.has('Scope of works')" class="help-block">
              {{ errors.first("Scope of works") }}
            </span>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Perimeter Fence Length" label-for="perimeterFenceLength" description>
            <b-form-input name="Perimeter Fence Length" type="text" v-model="form.perimeterFenceLength"
              v-validate="'required'" placeholder="Perimeter Fence Length" maxlength="100" />
            <span v-show="errors.has('Perimeter Fence Length')" class="help-block">
              {{ errors.first("Perimeter Fence Length") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Location" label-for="location" description="* Please enter the complete address">
            <b-form-input id="location" name="Location" type="text" v-model="form.location" v-validate="'required'"
              required placeholder="Location" />
            <span v-show="errors.has('Location')" class="help-block">
              {{ errors.first("Location") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Image" label-for="image" description>
            <b-form-file id="images" name="Image" :value="files" v-validate="'required'" ref="fileInput" accept="image/*"
              @change="onSelectImage" :disabled="images.length >= 4 && images.length <= 4" multiple no-drop
              placeholder="Upload maximum of 4 images only">
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
            <small v-if="errors.length > 0" class="text-danger">
              {{ errors[0] }}
            </small>
          </b-form-group>

          <!-- Preview Images -->
          <b-row class="form-row">
            <b-col md="6" v-for="(image, index) in images" :key="index" style="margin-bottom: 10px">
              <b-card overlay :img-src="image.url" img-alt="Card Image" img-height="250" text-variant="white">
                <b-card-text class="text-right">
                  <base-button type="danger" icon="fa fa-trash" size="sm" outline @click="onRemoveImage(index)" />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <div class="row" v-if="uploading.uploadStatus === 'uploading'">
        <div class="col-md-12">
          <base-progress class="py-0 my-0" type="danger" :value="uploading.percentage" :label="uploading.filename"
            animated></base-progress>
        </div>
      </div>
      <div class="row" v-else-if="uploading.uploadStatus === 'success'">
        <div class="col-md-12">
          <b-alert variant="success" class="py-2 my-0" show>
            <i class="fa fa-check"></i>
            &nbsp; Successfully saved!
          </b-alert>
        </div>
      </div>

      <base-button :disabled="isLoading" type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button :disabled="isLoading" type="primary" class="text-dark" @click="ok()">
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
//Components
import projectDAO from "../../database/projects";

// Util
import dateUtil from "../../utils/dateUtil";

//Others
import { bus } from "../../main";
import { storage } from "../../config/firebase";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import asyncForEach from "../../utils/asyncForEach";

export default {
  name: "add-project",
  components: {
    Loading,
  },

  data() {
    return {
      form: {
        title: "",
        clientName: "",
        location: "",
        scopeOfWorks: "",
        perimeterFenceLength: "",
        imageUrls: [],
        isActive: true,
        createdBy: "",
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: "",
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },
      images: [],
      uploading: {
        filename: "",
        percentage: 0,
        uploadStatus: "", // [uploading, success, error]
      },

      isLoading: false,
      isUploading: false,
      loggedUser: this.$store.getters["auth/loggedUser"],
    };
  },
  computed: {
    files() {
      return this.images.map((image) => image.file);
    },
    disableConfirmButtons() {
      return this.isLoading;
    },
  },
  methods: {
    async firebaseUploadImage(image) {
      let filename = `PROJECT-${Date.now()}`;
      this.uploadingInProgress("uploading", filename, 0);

      return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`images/projects/${filename}`);
        let task = storageRef.put(image.file);

        task.on(
          "state_changed",
          (snapshot) => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadingInProgress("uploading", filename, percentage);
          },
          (error) => {
            reject(error);
            this.resetUploadingState();
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({
                name: filename,
                url: downloadURL,
              });
            });
          }
        );
      });
    },

    async handleOk(evt) {
      evt.preventDefault();

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          "Please address the field/s with invalid input.",
          "Warning"
        );
        this.isLoading = false;
        return;
      }

      await this.handleSubmit();
    },

    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true;

        // Update Timestamps
        this.form.createdBy = this.loggedUser;
        this.form.dateCreated = dateUtil.getCurrentTimestamp();

        // Save the project details to the database
        let result = await projectDAO.createProject(this.form);
        const docId = result.id;
        console.log("finish saving data");

        let imageUrls = [];
        await asyncForEach(this.images, async (image) => {
          const imageFile = await this.firebaseUploadImage(image);
          imageUrls.push(imageFile);

          await projectDAO.updateProject({ imageUrls }, docId);
        });

        console.log("Done uploading images");
        console.log("done with all tasks");

        this.uploadingInProgress("success");
        this.$toast.success("New project was added", "Success!");

        this.$refs.modal.hide();
        bus.$emit("onCloseAddProject", this.form);

        setTimeout(() => {
          this.resetUploadingState();
        }, 2000);
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "There is an error adding project. Please try again.",
          "Error"
        );
      }
      // hide loading indicator
      this.isLoading = false;
    },
    uploadingInProgress(uploadStatus, filename = "", percentage = 0) {
      this.uploading.filename = filename;
      this.uploading.percentage = parseFloat(percentage.toFixed(2));
      this.uploading.uploadStatus = uploadStatus;
    },
    resetUploadingState() {
      this.uploading = {
        filename: "",
        percentage: 0,
        uploadStatus: "",
      };
    },
    onRemoveImage(index) {
      this.images.splice(index, 1);
    },
    onSelectImage(evt) {
      const selectedFiles = evt.target.files;
      const MAX_IMAGES = 4; // Maximum number of images allowed
      const MAX_IMAGE_SIZE = 2.5 * 1024 * 1024; // Maximum image size in bytes (2.5 MB)

      // If the number of selected images exceeds the maximum, show a warning
      if (selectedFiles.length > MAX_IMAGES) {
        this.$toast.warning(
          `You can only select ${MAX_IMAGES} images. Please remove excess images.`,
          "Warning"
        );
        return;
      }

      // Validate each selected file before adding it
      const validImages = [];
      for (const file of selectedFiles) {
        // Check if the file size exceeds the maximum allowed
        if (file.size > MAX_IMAGE_SIZE) {
          this.$toast.warning(
            `The file "${file.name}" exceeds the maximum size of 2.5 MB. Please select a smaller file.`,
            "Warning"
          );
          continue; // Skip this file and move to the next one
        }

        // Add the valid file to the list of images
        validImages.push({
          url: URL.createObjectURL(file),
          file: file,
        });
      }

      // Update the component's images with the valid images
      this.images = validImages;
    },

    onReset() {
      this.form = {
        title: "",
        clientName: "",
        location: "",
        scopeOfWorks: "",
        perimeterFenceLength: "",
        imageUrls: [],
        isActive: true,
        createdBy: "",
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: "",
        dateUpdated: dateUtil.getCurrentTimestamp(),
      };

      // reset validation
      this.isLoading = false;
      this.$validator.reset();
      this.errors.clear();
      this.images = [];
    },
  },
};
</script>
