<template>
  <b-modal
    id="activate-project"
    title="Project Activation"
    ok-title="Save"
    ref="modal"
    @close="handleCancel"
    @cancel="handleCancel"
    @ok="handleOk"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
  >
    <br />
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />
    <div>
      <span v-if="isActive">
        Are you sure you want to
        <b>deactivate</b>
        project
        <b>{{ selProject.title }}?</b>
      </span>
      <span v-else>
        Are you sure you want to
        <b>activate</b>
        project
        <b>{{ selProject.title }}?</b>
      </span>
    </div>
    <br />
  </b-modal>
</template>

<script>
// DAO
import projectDAO from '../../database/projects'

// Util
import dateUtil from '../../utils/dateUtil'

// Others
import { bus } from '../../main'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'activate-project',
  components: {
    Loading,
  },
  data() {
    return {
      selProject: {},
      isActive: true,

      // Check for loader
      isLoading: false,
      loggedUser: this.$store.getters['auth/loggedUser'],
    }
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading
    },
  },
  mounted() {
    bus.$on('onUpdateSelProject', (selProject) => {
      this.selProject = Object.assign({}, selProject)
      this.isActive = selProject.isActive
    })
  },
  methods: {
    resetStatus() {
      this.selProject.isActive = this.isActive
      this.$store.commit('SET_CURR_PROJECT', this.selProject)
    },
    async handleCancel() {
      this.resetStatus()
      bus.$emit('onCloseActivateProject', this.selProject)
      this.isLoading = false
    },
    async handleOk() {
      try {
        // Show loading indicator
        this.isLoading = true

        // Update status
        this.selProject.isActive = !this.isActive
        // Update Timestamps
        this.selProject.updatedBy = this.loggedUser
        this.selProject.dateUpdated = dateUtil.getCurrentTimestamp()

        await projectDAO.updateProject(this.selProject, this.selProject.id)

        let activationLabel = this.selProject.isActive
          ? 'activated'
          : 'deactivated'
        this.$toast.success(
          `Project ${this.selProject.title} account is now ${activationLabel}!`,
          'Success',
        )
        this.$refs.modal.hide()
        bus.$emit('onCloseActivateProject', this.selProject)
      } catch (error) {
        console.error(error)
        let errorLabel = this.selProject.isActive
          ? 'activating'
          : 'deactivating'
        this.$toast.error(
          `There is an error ${errorLabel} project ${this.selProject.title}. Please try again.`,
          'Error',
        )
      }

      // Hide loading indicator
      this.isLoading = false
    },
  },
  beforeDestroy() {
    bus.$off('onUpdateSelProject')
  },
}
</script>
