<template>
  <div class="container-fluid">
    <b-card>
      <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

      <template v-slot:header>
        <i class="fa fa-calculator text-primary"></i>
        &nbsp;
        <strong>Quote Requests</strong>
        &nbsp;
        <small>Quotation Requests</small>
      </template>

      <b-row>
        <b-col sm="12">
          <base-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
            class="secondary filter mb-2">
            Filter Options
          </base-button>
        </b-col>
        <b-col sm="12">
          <b-collapse id="collapse-1" class="mt-2 mb-4">
            <b-card>
              <!-- Filter by Date Range -->
              <b-row no-gutters>
                <b-col lg="4" md="5" sm="12" class="mr-4">
                  <b-form-group label="Date From">
                    <b-form-datepicker name="Date From" v-model="filterBy.dateFrom" locale="en" reset-button
                      label-reset-button="Clear" :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short',
                      }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                    <span v-show="errors.has('Date From')" class="help-block">
                      {{ errors.first('Date From') }}
                    </span>
                  </b-form-group>
                </b-col>
                <b-col lg="4" md="5" sm="12" class="mr-4">
                  <b-form-group label="Date To">
                    <b-form-datepicker name="Date To" v-model="filterBy.dateTo" locale="en" reset-button
                      label-reset-button="Clear" :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short',
                      }" :date-disabled-fn="dateFromDisabled" v-validate="'required'" />
                    <span v-show="errors.has('Date To')" class="help-block">
                      {{ errors.first('Date To') }}
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row no-gutters>
                <b-col sm="12">
                  <b-button class="mr-1 easyhomes-yellow" @click="onFilterRequest">
                    Search
                  </b-button>
                  <b-button class="mr-1 btn-secondary" @click="resetFilters">
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>
        </b-col>
      </b-row>

      <b-card-text>
        <b-row class="mb-3">
          <b-col sm="6" md="3">
            <base-dropdown position="left">
              <base-button slot="title" type="primary" class="">
                Select Actions <i class="fas fa-angle-down ml-2"></i>
              </base-button>
              <b-dropdown-item>
                <json-excel :data="exportData" :fields="exportFields" type="xls" :name="fileName + '.xls'">
                  Export Quote Requests in Excel
                </json-excel>
              </b-dropdown-item>
              <b-dropdown-item>
                <json-excel :data="exportData" :fields="exportFields" type="csv" :name="fileName + '.csv'">
                  Export Quote Requests to CSV
                </json-excel>
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
          <b-col sm="6" md="4" offset-md="5" class="text-md-right">
            <base-dropdown position="right">
              <base-button slot="title" type="primary" class="dropdown-toggle">
                Show {{ perPage }} / Page
              </base-button>
              <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
                {{ option }}
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
        </b-row>
      </b-card-text>

      <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage"
        :per-page="perPage" responsive show-empty style="min-height: 380px">
        <template v-slot:cell(dateCreated)="{ item }">
          {{ item.dateCreated | dateAndTimeFormatter }}
        </template>

        <template v-slot:cell(name)="row">
          {{ getName(row.item) }}
        </template>

        <template v-slot:cell(status)="row">
          <span v-if="row.item.isOnGoing">
            <b-badge variant="primary">On-Going</b-badge>
          </span>
          <span v-else>
            <b-badge variant="success">Done</b-badge>
          </span>
        </template>

        <template v-slot:cell(actions)="row">
          <b-button title="Show/Hide Other Details" variant="success" @click.stop="row.toggleDetails" size="sm">
            <b-icon-eye />
          </b-button>

          &nbsp;
          <b-button title="Download File" variant="primary" @click.stop="updateSelRequest(row.item)"
            v-b-modal.download-pdf v-b-tooltip.hover.top="'Download Image'" size="sm" v-if="row.item.projectName">
            <b-icon-box-arrow-down />
          </b-button>

          &nbsp;
          <b-button title="Mark as Done" variant="info" v-b-modal.mark-request-as-done v-b-tooltip.hover.top="'Done'"
            @click.stop="updateSelRequest(row.item)" v-show="row.item.isOnGoing" size="sm">
            <b-icon-check />
          </b-button>
        </template>

        <template slot="row-details" slot-scope="row">
          <b-card>
            <b-tabs>

              <b-tab title="Primary Details">
                <b-row class="mx-2 my-3">
                  <b-input-group>
                    <b-col>
                      <p>
                        <span style="font-weight: 700"> Name: </span>
                        <span class="ml-3">
                          {{ getName(row.item) }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Phone Number: </span>
                        <span class="ml-3">
                          {{ row.item.phoneNumber }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700">
                          Profession/Role:
                        </span>
                        <span class="ml-3">
                          {{ row.item.role }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Email: </span>
                        <span class="ml-3">
                          {{ row.item.email }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Company: </span>
                        <span class="ml-3">
                          {{ row.item.company }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700">
                          Company Address:
                        </span>
                        <span class="ml-3">
                          {{ row.item.companyAddress }}
                        </span>
                      </p>
                    </b-col>
                  </b-input-group>
                </b-row>
                <b-row class="mt-4 mb-2">
                  <b-col class="text-right">
                    <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                      size="sm">
                      <b-icon-arrow-up />
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Additional Info">
                <b-row class="mx-2 my-3">
                  <b-input-group>
                    <b-col>
                      <p>
                        <span style="font-weight: 700">
                          How do you hear about us?
                        </span>
                        <span class="ml-3">
                          {{ row.item.selection }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Message: </span>
                        <span class="ml-3">
                          {{ row.item.message }}
                        </span>
                      </p>
                    </b-col>
                  </b-input-group>
                </b-row>

                <b-row class="mt-4 mb-2">
                  <b-col class="text-right">
                    <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                      size="sm">
                      <b-icon-arrow-up />
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="row.item.projectName" title="Project Information">
                <b-row class="mx-2 my-3">
                  <b-input-group>
                    <b-col lg="2" md="2" sm="2" cols="2">
                      <p>
                        <span style="font-weight: 700"> Project Name: </span>
                        <span class="ml-3">
                          {{ row.item.projectName }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Project Type: </span>
                        <span class="ml-3">
                          {{ row.item.selectionType }}
                        </span>
                      </p>

                      <p>
                        <span style="font-weight: 700">
                          Project Location:
                        </span>
                        <span class="ml-3">
                          {{ row.item.projectLocation }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Height: </span>
                        <span class="ml-3">
                          {{ row.item.selectionHeight }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Length: </span>
                        <span class="ml-3">
                          {{ row.item.totalLength }}
                        </span>
                      </p>
                      <p>
                        <span style="font-weight: 700"> Support: </span>
                        <span class="ml-3">
                          {{ row.item.selectionWire }}
                        </span>
                      </p>
                    </b-col>
                  </b-input-group>
                </b-row>

                <b-row class="mt-4 mb-2">
                  <b-col class="text-right">
                    <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                      size="sm">
                      <b-icon-arrow-up />
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="row.item.projectName" title="Design Drawing">
                <b-row class="mx-2 my-3">
                  <b-input-group>
                    <b-col>
                      <img :src="row.item.renderImage" style="width: 30%" class="img-fluid" alt="render image" />
                    </b-col>
                  </b-input-group>
                </b-row>
                <b-row class="mt-4 mb-2">
                  <b-col class="text-right">
                    <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                      size="sm">
                      <b-icon-arrow-up />
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab v-if="row.item.projectName" title="PDF File">
                <b-row class="mx-2 my-3">
                  <b-input-group>
                    <b-col>
                      <span style="font-weight: 700"> PDF: </span>
                      <span v-if="row.item.fileURL">
                        <a target="_blank" class="text-underline" :href="row.item.fileURL" download>
                          {{ createFilePDFName(row.item.fileURL) }}
                        </a>
                      </span>
                      <span v-else>
                        N/A
                      </span>
                    </b-col>
                  </b-input-group>
                </b-row>
                <b-row class="mt-4 mb-2">
                  <b-col class="text-right">
                    <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                      size="sm">
                      <b-icon-arrow-up />
                    </b-button>
                  </b-col>
                </b-row>
              </b-tab>

            </b-tabs>
          </b-card>
        </template>
      </b-table>

      <template v-slot:footer>
        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="totalDisplay">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
              class="my-0" />
          </b-col>
        </b-row>
      </template>

      <MarkRequestAsDone />
      <DownloadPDF />
    </b-card>
  </div>
</template>

<script>
// Components
import DownloadPDF from "./requests/DownloadPDF";
import MarkRequestAsDone from "./requests/MarkRequestAsDone.vue";

// DAO
import contactFormDAO from "../database/contactForms";

// Others
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { bus } from "../main";
import JsonExcel from 'vue-json-excel';
import _ from 'lodash'
import moment from 'moment';

// Util
import dateUtil from '@/utils/dateUtil'

export default {
  name: "requests",
  components: {
    Loading,
    MarkRequestAsDone,
    DownloadPDF,
    JsonExcel
  },
  data() {
    return {
      items: [],
      allRequestsObj: {},
      fields: [
        { key: "requestId", label: "Request ID", sortable: true },
        { key: "dateCreated", label: "Date Created", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, 50, 75, 100],

      defaultFilterBy: {
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
      },
      filterBy: {
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().format('YYYY-MM-DD'),
      },

      //loader
      isLoading: false,
      selRequestIndex: -1,
      loggedUser: this.$store.getters["auth/loggedUser"],
    };
  },

  computed: {
    dateFrom() {
      const dateTo = moment();
      const dateFrom = dateTo.add(-30, 'days');
      return dateFrom.format('YYYY-MM-DD');
    },
    dateTo() {
      return moment().format('YYYY-MM-DD');
    },
    /**
     * Returns the set of data to be included in the export. For now this just
     * returns the data as is.
     *
     * @returns {Array} the set of data to be included in the export.
     */
    exportData() {
      const exportedData = _.map(this.items, item => {
        const dateCreated = item.dateCreated ? dateUtil.getFormattedDate(item.dateCreated) : '-';
        const requestId = item.requestId ? item.requestId : '-';
        const email = item.email ? item.email : '-';
        const phoneNumber = item.phoneNumber ? item.phoneNumber : '-';
        const cost = item.cost ? item.cost : '-';
        const projectName = item.projectName ? item.projectName : '-';
        const projectLocation = item.projectLocation ? item.projectLocation : '-';
        const selectionType = item.selectionType ? item.selectionType : '-';
        const selectionHeight = item.selectionHeight ? item.selectionHeight : '-';
        const selectionWire = item.selectionWire ? item.selectionWire : '-';
        const totalLength = item.totalLength ? item.totalLength : '-';
        const message = item.message ? item.message : '-';
        const isOnGoing = item.isOnGoing === true ? 'Done' : 'On-going';

        const firstName = item.firstname ? item.firstname : '-';
        const lastName = item.lastname ? item.lastname : '-';
        const name = firstName + " " + lastName;

        return {
          'dateCreated': dateCreated,
          'requestId': requestId,
          'name': name,
          'email': email,
          'phoneNumber': phoneNumber,
          'cost': cost,
          'projectName': projectName,
          'projectLocation': projectLocation,
          'type': selectionType,
          'height': selectionHeight,
          'wire': selectionWire,
          'totalLength': totalLength,
          'message': message,
          'status': isOnGoing
        };
      });

      return exportedData;
    },

    /**
     * Derives the field information based from the data table configuration.
     *
     * @returns {object} the fields to be included in the export.
     */
    exportFields() {
      return {
        'Date Created': 'dateCreated',
        'Request Id': 'requestId',
        'Name': 'name',
        'Email': 'email',
        'Phone Number': 'phoneNumber',
        'Cost': 'cost',
        'Project Name': 'projectName',
        'Project Location': 'projectLocation',
        'Type': 'type',
        'Height': 'height',
        'Wire': 'wire',
        'Total Length': 'totalLength',
        'Message': 'message',
        'Status': 'status'
      };
    },

    fileName() {
      let currTimeStamp = dateUtil.getCurrentTimestamp();
      return 'Request-' + dateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
    },
  },

  created() {
    this.getQuote();
  },

  async mounted() {
    this.defaultFilterBy.dateFrom = this.dateFrom;
    this.defaultFilterBy.dateTo = this.dateTo;

    this.filterBy.dateFrom = this.dateFrom;
    this.filterBy.dateTo = this.dateTo;

    // pre-load products
    await this.getQuote()

    bus.$on('onCloseSelRequest', () => this.getQuote())
  },


  methods: {
    dateFromDisabled(_ymd, date) {
      return date > new Date();
    },
    showDownloadModal() {
      bus.$emit("onDownloadPDF");
    },
    async getQuote() {
      try {
        this.isLoading = true;

        let filter = { ...this.filterBy };

        filter.fromTimestamp = dateUtil.startDateTimeStamp(new Date(filter.dateFrom));
        filter.toTimestamp = dateUtil.endDateTimeStamp(new Date(filter.dateTo));

        this.allRequestsObj = await contactFormDAO.retrieveFilteredRequests(filter);
        this.items = Object.values(this.allRequestsObj);

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    getName(item) {
      let name = "";
      name += item.firstname + " ";
      name += item.lastname;
      return name;
    },

    createFilePDFName() {
      const prefix = "DOWNLOAD";
      const date = new Date();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = String(date.getFullYear());

      return `${prefix}-${month}${day}${year}`;
    },
    updateSelRequest(item) {
      this.selRequest = item;
      this.$store.commit("contactForm/SET_CURR_REQUEST", item);
      bus.$emit("onUpdateRequest", item);
    },

    async onFilterRequest() {
      try {
        this.isLoading = true;

        let isValid = await this.$validator.validateAll();
        if (!isValid) {
          this.$toaster.warning('Please address the field/s with invalid input.');
          this.isLoading = false;
          return;
        }

        if (!this.validateFilter()) {
          this.isLoading = false;
          return;
        }

        await this.getQuote();

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    async resetFilters() {
      this.filterBy = { ...this.defaultFilterBy };

      await this.getQuote();
    },
    validateFilter() {
      let isValid = true;

      if (_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo)) {
        this.$toaster.warning('Date From and Date To are required.');
        isValid = false;
      } else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo)) ||
        (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
        this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
        isValid = false;
      } else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
        this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
        isValid = false;
      }

      return isValid;
    },
  },
  beforeDestroy() {
    bus.$off('onCloseSelRequest')
  },
}
</script>

<style scoped>
.bg-button {
  background: none !important;
  border: none !important;
  background-color: gray !important;
}

.filter {
  color: #eda711;
  background-color: white !important;
  border-color: #eda711 !important;
  width: 162px;
}

.easyhomes-yellow {
  background-color: #eda711 !important;
  border-color: #eda711 !important;
  color: white !important;
}
</style>
