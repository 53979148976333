<template>
  <b-modal id="edit-project" size="lg" title="Edit Project" ref="modal" @ok="handleOk" @show="onReset" @cancel="onReset"
    :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true"
    :no-close-on-esc="true">
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

    <b-form @submit.stop.prevent="handleSubmit" novalidate>
      <div class="form-row mt-4">
        <div class="col-md-6">
          <b-form-group label="Title" label-for="title" description>
            <b-form-input name="Title" type="text" v-model="form.title" v-validate="'required'" placeholder="Title"
              maxlength="25" />
            <span v-show="errors.has('Title')" class="help-block">
              {{ errors.first("Title") }}
            </span>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Client Name" label-for="clientName" description>
            <b-form-input name="Client Name" type="text" v-model="form.clientName" v-validate="'required'"
              placeholder="Client Name" maxlength="25" />
            <span v-show="errors.has('Client Name')" class="help-block">
              {{ errors.first("Client Name") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <b-form-group label="Scope of works" label-for="scopeOfWorks" description>
            <b-form-input name="Scope of works" type="text" v-model="form.scopeOfWorks" v-validate="'required'"
              placeholder="Scope of works" maxlength="100" />
            <span v-show="errors.has('Scope of works')" class="help-block">
              {{ errors.first("Scope of works") }}
            </span>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <b-form-group label="Perimeter Fence Length" label-for="perimeterFenceLength" description>
            <b-form-input name="Perimeter Fence Length" type="text" v-model="form.perimeterFenceLength"
              v-validate="'required'" placeholder="Perimeter Fence Length" maxlength="100" />
            <span v-show="errors.has('Perimeter Fence Length')" class="help-block">
              {{ errors.first("Perimeter Fence Length") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Location" label-for="location" description="* Please enter the complete address">
            <b-form-input id="location" name="Location" type="text" v-model="form.location" v-validate="'required'"
              required placeholder="Location" />
            <span v-show="errors.has('Location')" class="help-block">
              {{ errors.first("Location") }}
            </span>
          </b-form-group>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-12">
          <b-form-group label="Image" label-for="image">
            <b-form-file id="images" placeholder="Upload maximum of 4 images only" ref="fileInput" accept="image/*"
              @change="onSelectImage" :disabled="images.length >= 4 && images.length <= 4" multiple no-drop>
              <template slot="file-name" slot-scope="{ names }">
                <b-badge variant="dark">{{ names[0] }}</b-badge>
                <b-badge v-if="names.length > 1" variant="dark" class="ml-1">
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
          </b-form-group>

          <!-- Preview Images -->
          <b-row class="form-row">
            <b-col md="6" v-for="(image, index) in images" :key="index" style="margin-bottom: 10px">
              <b-card overlay :img-src="image.url" img-alt="Card Image" img-height="250" text-variant="white">
                <b-card-text class="text-right">
                  <base-button type="danger" icon="fa fa-trash" size="sm" outline @click="onRemoveImage(index)" />
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-form>

    <template #modal-footer="{ ok, cancel }">
      <div class="row" v-if="uploading.uploadStatus === 'uploading'">
        <div class="col-md-12">
          <base-progress class="py-0 my-0" type="danger" :value="uploading.percentage" :label="uploading.filename"
            animated></base-progress>
        </div>
      </div>
      <div class="row" v-else-if="uploading.uploadStatus === 'success'">
        <div class="col-md-12">
          <b-alert variant="success" class="py-2 my-0" show>
            <i class="fa fa-check"></i>
            &nbsp; Successfully saved!
          </b-alert>
        </div>
      </div>

      <base-button type="secondary" class="text-dark" @click="cancel()">
        Close
      </base-button>
      <base-button type="primary" class="text-dark" @click="ok()">
        Save
      </base-button>
    </template>
  </b-modal>
</template>

<script>
//DAO
import projectDAO from "../../database/projects";

// Util
import dateUtil from "../../utils/dateUtil";

//Others
import { bus } from "../../main";
import { storage } from "../../config/firebase";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import asyncForEach from "../../utils/asyncForEach";

export default {
  name: "edit-project",
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        title: "",
        clientName: "",
        location: "",
        scopeOfWorks: "",
        perimeterFenceLength: "",
        imageUrls: [],
        isActive: true,
        createdBy: "",
        dateCreated: dateUtil.getCurrentTimestamp(),
        updatedBy: "",
        dateUpdated: dateUtil.getCurrentTimestamp(),
      },
      images: [],
      uploading: {
        filename: "",
        percentage: 0,
        uploadStatus: "", // [uploading, success, error]
      },

      // Check for loader
      isLoading: false,
      isUploading: false,
      loggedUser: this.$store.getters["auth/loggedUser"],
    };
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
  },

  methods: {
    async handleOk(evt) {
      evt.preventDefault();

      if (!(await this.$validator.validateAll())) {
        this.$toast.warning(
          "Please address the field/s with invalid input.",
          "Warning"
        );
        this.isLoading = false;
        return;
      }

      await this.handleSubmit();
    },

    async firebaseUploadImage(image) {
      let filename = `PROJECT-${Date.now()}`;
      this.uploadingInProgress("uploading", filename, 0);

      return new Promise((resolve, reject) => {
        let storageRef = storage.ref(`images/projects/${filename}`);
        let task = storageRef.put(image.file);

        task.on(
          "state_changed",
          (snapshot) => {
            let percentage =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.uploadingInProgress("uploading", filename, percentage);
          },
          (error) => {
            reject(error);
            this.resetUploadingState();
          },
          () => {
            task.snapshot.ref.getDownloadURL().then((downloadURL) => {
              resolve({
                name: filename,
                url: downloadURL,
              });
            });
          }
        );
      });
    },

    async handleSubmit() {
      try {
        // show loading indicator
        this.isLoading = true;

        // Update Timestamps
        this.form.updatedBy = this.loggedUser;
        this.form.dateUpdated = dateUtil.getCurrentTimestamp();

        await projectDAO.updateProject(this.form, this.form.id);
        const docId = this.form.id;
        console.log("finish saving data");

        let imageUrls = [];

        const oldImages = this.images.filter((item) => !item._isNew);
        imageUrls = oldImages;

        const newImages = this.images.filter((item) => item._isNew);
        if (newImages.length > 0) {
          await asyncForEach(newImages, async (image) => {
            const imageFile = await this.firebaseUploadImage(image);
            imageUrls.push(imageFile);
          });
        }

        await projectDAO.updateProject({ imageUrls }, docId);

        this.uploadingInProgress("success");
        this.$toast.success(this.form.title + " was updated.", "Success");
        this.$refs.modal.hide();
        bus.$emit("onCloseEditProject", this.form);

        setTimeout(() => {
          this.resetUploadingState();
        }, 2000);
      } catch (error) {
        console.error(error);
        this.$toast.error(
          "There is an error updating project. Please try again.",
          "Error"
        );
      }
      // hide loading indicator
      this.isLoading = false;
    },

    uploadingInProgress(uploadStatus, filename = "", percentage = 0) {
      this.uploading.filename = filename;
      this.uploading.percentage = parseFloat(percentage.toFixed(2));
      this.uploading.uploadStatus = uploadStatus;
    },
    resetUploadingState() {
      this.uploading = {
        filename: "",
        percentage: 0,
        uploadStatus: "",
      };
    },
    onRemoveImage(index) {
      this.images.splice(index, 1);
    },
    onSelectImage(evt) {
      const selectedFiles = evt.target.files;
      const MAX_IMAGES = 4; // Maximum number of images allowed

      // If the number of selected images exceeds the maximum, show a warning
      if (selectedFiles.length > MAX_IMAGES) {
        this.$toast.warning(
          `You can only select ${MAX_IMAGES} images. Please remove excess images.`,
          "Warning"
        );
        return;
      }
      const files = evt.target.files;
      files.forEach((file) => {
        this.images.push({
          url: URL.createObjectURL(file),
          file: file,
          _isNew: true,
        });
      });
      this.form.imageUrls = [];
    },
    onReset() {

      this.isLoading = true;

      let project = this.$store.getters["project/currProject"];
      let projectJSON = JSON.stringify(project);
      this.form = JSON.parse(projectJSON);

      const imageUrls = this.form.imageUrls;
      if (imageUrls && imageUrls.length !== 0) {
        this.images = [];
        for (const image of imageUrls) {
          this.images.push(image);
        }
      }

      // reset validation
      this.isLoading = false;
      this.$validator.reset();
      this.errors.clear();
    },
  },
};
</script>
