<template>
  <div class="container-fluid">
    <b-card>
      <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

      <template v-slot:header>
        <i class="fa fa-handshake text-primary"></i>
        &nbsp;
        <strong>Projects</strong>
        &nbsp;
        <small>Lists of EHI Projects</small>
      </template>

      <b-card-text>
        <b-row class="mb-3">
          <b-col sm="6" md="3">
            <b-dropdown text=" Select Actions " variant="primary" slot="append">
              <b-dropdown-item v-b-modal.add-project>
                <i class="fa fa-plus"></i>
                Add New Project
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.reorder-project @click="eventBus.$emit('ReorderProjectDialog', items)">
                <i class="fa fa-sort"></i>
                Re-order Projects
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="6" md="4" offset-md="5" class="text-md-right">
            <base-dropdown position="right">
              <base-button slot="title" type="primary" class="dropdown-toggle">
                Show {{ perPage }} / Page
              </base-button>
              <b-dropdown-item v-for="(option, index) in pageOptions" :key="index" @click="perPage = option">
                {{ option }}
              </b-dropdown-item>
            </base-dropdown>
          </b-col>
        </b-row>

        <b-table :items="items" :fields="fields" selected-variant="primary" :current-page="currentPage"
          :per-page="perPage" :sort-by.sync="sortBy" responsive show-empty style="min-height: 380px">
          <template v-slot:cell(dateUpdated)="{ item }">
            {{ item.dateUpdated | dateAndTimeFormatter }}
          </template>

          <template v-slot:cell(actions)="row">
            <b-button title="Show/Hide Other Details" variant="success" @click.stop="row.toggleDetails" size="sm">
              <b-icon-eye />
            </b-button>

            &nbsp;
            <b-button title="Edit Project" size="sm" v-b-modal.edit-project v-b-tooltip.hover.top="'Edit Project Detail'"
              variant="info" @click.stop="updateSelProject(row.item)">
              <b-icon-pen />
            </b-button>
          </template>

          <template v-slot:cell(status)="row">
            <div>
              <a @click="updateSelProject(row.item)">
                <b-form-checkbox name="status" v-b-modal.activate-project v-model="row.item.isActive" switch>
                  {{ row.item.isActive ? "Active" : "Inactive" }}
                </b-form-checkbox>
              </a>
            </div>
          </template>

          <template slot="row-details" slot-scope="row">
            <b-card>
              <b-tabs>
                <b-tab title="Project Details">
                  <b-row class="mx-2 my-3">
                    <b-input-group>
                      <b-col>
                        <p>
                          <span style="font-weight: 700">Client Name:</span>
                          <span class="ml-3">{{ row.item.clientName }}</span>
                        </p>
                        <p>
                          <span style="font-weight: 700">Location:</span>
                          <span class="ml-3">{{ row.item.location }}</span>
                        </p>
                        <p>
                          <span style="font-weight: 700">Scope of Works:</span>
                          <span class="ml-3">{{ row.item.scopeOfWorks }}</span>
                        </p>
                        <p>
                          <span style="font-weight: 700">
                            Perimeter Fence Length:
                          </span>
                          <span class="ml-3">
                            {{ row.item.perimeterFenceLength }}
                          </span>
                        </p>
                      </b-col>
                    </b-input-group>
                  </b-row>
                  <b-row class="mt-4 mb-2">
                    <b-col class="text-right">
                      <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                        size="sm">
                        <b-icon-arrow-up />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab title="Images">
                  <b-row class="mx-2 my-3">
                    <b-input-group>
                      <b-col>
                        <div class="d-flex flex-row">
                          <div v-for="(imageUrl, key) in row.item.imageUrls" :key="key" class="col-lg-3">
                            <img :src="imageUrl.url" style="width: 27rem; height: 100%" class="img-fluid mr-5"
                              alt="Image" />
                          </div>
                        </div>
                      </b-col>
                    </b-input-group>
                  </b-row>
                  <b-row class="mt-4 mb-2">
                    <b-col class="text-right">
                      <b-button @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover title="Hide Details"
                        size="sm">
                        <b-icon-arrow-up />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-tab>
              </b-tabs>
            </b-card>
          </template>
        </b-table>
      </b-card-text>

      <template v-slot:footer>
        <b-row>
          <b-col md="8" sm="12" class="my-1">
            <span class="totalDisplay">Total: {{ items.length }}</span>
          </b-col>
          <b-col md="4" sm="12" class="my-1">
            <b-pagination align="right" :total-rows="items.length" :per-page="perPage" v-model="currentPage"
              class="my-0" />
          </b-col>
        </b-row>
      </template>
    </b-card>

    <!-- Modals -->
    <ReorderProject />
    <AddProject />
    <EditProject />
    <ActivateProject />
  </div>
</template>

<script>
//Components
import ReorderProject from './projects/ReorderProject'
import AddProject from "./projects/AddProject";
import EditProject from "./projects/EditProject";
import ActivateProject from "./projects/ActivateProject";

//DAO
import projectDAO from "../database/projects";

//Others
import { bus } from "../main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "projects",
  components: {
    ReorderProject,
    AddProject,
    EditProject,
    ActivateProject,
    Loading,
  },
  data() {
    return {
      selProject: {},
      allProjectsObj: {},
      items: [],
      fields: [
        { key: "order", label: "Order", sortable: true },
        { key: "title", label: "Title", sortable: true },
        { key: "dateUpdated", label: "Date updated" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
      ],
      eventBus: bus,
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 25, 50, 75, 100],
			sortBy: 'order',

      isLoading: false,
    };
  },

  computed: {
    exportData() {
      let allProjectsArr = [];
      this.items.forEach((item) => {
        let project = Object.assign({}, item);
        project.isActive = item.isActive ? "Active" : "Inactive";

        if (project._showDetails) {
          delete project._showDetails;
        }

        allProjectsArr.push(project);

      });
      return allProjectsArr;
    },
  },

  async mounted() {
    // pre-load projects
    await this.getAllProjects();

    bus.$on("onCloseReOrderProject", () => this.getAllProjects());
    bus.$on("onCloseAddProject", () => this.getAllProjects());
    bus.$on("onCloseEditProject", () => this.getAllProjects());
    bus.$on("onCloseActivateProject", () => this.getAllProjects());
  },

  methods: {
    async getAllProjects() {
      try {
        this.isLoading = true;
        this.allProjectsObj = await projectDAO.retrieveAll();
        this.items = Object.values(this.allProjectsObj);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    updateSelProject(item) {
      this.selProject = item;
      this.$store.commit("project/SET_CURR_PROJECT", item);
      bus.$emit("onUpdateSelProject", item);
    },
  },

  beforeDestroy() {
    bus.$off("onCloseAddProject");
    bus.$off("onCloseEditProject");
    bus.$off("onCloseActivateProject");
  },
};
</script>
