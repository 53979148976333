var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal",attrs:{"id":"edit-sales-contacts","size":"sm","title":"Edit Sales Contacts","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true,"no-close-on-esc":true},on:{"ok":_vm.handleOk,"show":_vm.onReset},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('base-button',{staticClass:"text-dark",attrs:{"type":"secondary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Close ")]),_c('base-button',{staticClass:"text-dark",attrs:{"type":"primary"},on:{"click":function($event){return ok()}}},[_vm._v(" Save ")])]}}])},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('div',{staticClass:"form-row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
              regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,
            }),expression:"{\n              required: true,\n              regex: /^([ÑA-Za-z][ñA-Za-z- ]{1,30})$/,\n            }"}],attrs:{"name":"Name","type":"text","placeholder":"Name","maxlength":"25"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Name')),expression:"errors.has('Name')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Name'))+" ")])],1)],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-md-12"},[_c('b-form-group',{attrs:{"label":"Contact Number","label-for":"contactNumber","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,

            }),expression:"{\n              required: true,\n\n            }"}],attrs:{"name":"Contact Number","type":"number"},model:{value:(_vm.form.contactNumber),callback:function ($$v) {_vm.$set(_vm.form, "contactNumber", $$v)},expression:"form.contactNumber"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Contact Number')),expression:"errors.has('Contact Number')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Contact Number'))+" ")])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }