<template>
  <b-modal id="download-pdf" ref="modal" @show="onReset" @close="closeModal" @ok="downloadRequest" size="xl"
    :no-close-on-backdrop="true" no-close-on-esc hide-backdrop centered>
    <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
    <b-container fluid id="request-summary">
      <!-- header -->
      <div class="header-title row mb-3">
        <div class="col-md-8 quote-container">
          <h2 class="text-primary mb-0">DESIGN QUOTE</h2>
          <h2 class="mb-0">{{ selRequest.requestId }}</h2>
          <p>
            {{ selRequest.dateCreated | dateAndTimeFormatter }}
          </p>
        </div>

        <div class="col-md-4">
          <div class="quote-brand">
            <img src="../../../public/img/brand/brand-cut.png" class="img-fluid float-right" alt="ehi" />
          </div>
        </div>
      </div>

      <template>
        <!-- data -->
        <div class="row">
          <div class="col-md-6">
            <div class="col">
              <fieldset>
                <legend class="text-primary mb-0 pr-3">
                  PROJECT DETAILS
                  <span class="legend-line"></span>
                </legend>
                <div class="row data mt-2">
                  <div class="col-md-4">
                    <p>Project Name:</p>
                    <p>Company:</p>
                    <p>Location:</p>
                  </div>
                  <div class="col-md-6">
                    <p>
                      <span>{{ selRequest.projectName }}</span>
                    </p>
                    <p>
                      <span>{{ selRequest.company }}</span>
                    </p>
                    <p>
                      <span>{{ selRequest.projectLocation }}</span>
                    </p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div class="col-md-6">
            <div class="col">
              <fieldset>
                <legend class="text-primary mb-0 pr-3">
                  DESIGN DRAWING
                  <span class="legend-line"></span>
                </legend>
                <div class="col-md-9 m-4 d-flex justify-content-center">
                  <img :src="selRequest.renderImage" class="img-fluid img-border" ref="imageElement"
                    alt="image not available" />
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <div class="row data">
          <div class="col-md-6">
            <div class="col">
              <fieldset>
                <legend class="text-primary mb-0 pr-3">
                  PROJECT SPECIFICATIONS
                  <span class="legend-line-project-spec"></span>
                </legend>
                <div class="row mt-2">
                  <div class="col-md-4">
                    <p>Total Height(m):</p>
                    <p>Total Length(m):</p>
                    <p>With Barbed Wire?</p>
                  </div>
                  <div class="col-md-6">
                    <p>
                      <span>{{ selRequest.selectionHeight }}</span>
                    </p>
                    <p>
                      <span>{{ selRequest.totalLength }}</span>
                    </p>
                    <p>
                      <span>{{ selRequest.selectionWire }}</span>
                    </p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="col-md-6">
            <div class="col">
              <fieldset>
                <legend class="text-primary mb-0 pr-3">
                  ESTIMATED COST
                  <span class="legend-line"></span>
                </legend>
                <div class="row mt-2">
                  <div class="col-md-2">
                    <p>Total:</p>
                  </div>
                  <div class="col-md-6">
                    <p class="text-underline">
                      <span style="font-size: 1.25rem">
                        {{ selRequest.cost }} PHP
                      </span>
                    </p>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </template>

      <div class="row mt-4">
        <span class="italic-text">
          DISCLAIMER: Above quotation is indicative and for budgetary use only.
          For more accurate quote, kindly contact us and we can schedule a site
          visit.
        </span>
      </div>

      <b-row class="mt-3 footer">
        <b-col sm="3" align="left">
          <img src="../../../public/img/brand/brand-logo.png" width="auto" height="30" alt="ehi" />
        </b-col>

        <b-col class="footer-col" sm="9" align="right">
          Diezmo Rd., Brgy., Pulo, Cabuyao, Laguna, Philippines, 4025 | 02 519
          4042 | 0917 869 8576
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer="{ ok, close }">
      <base-button @click="close()" type="secondary" class="text-dark" :disabled="isLoading">
        Close
      </base-button>

      <base-button @click="ok()" type="primary" class="text-dark" :disabled="isLoading">
        Download
      </base-button>
    </template>
  </b-modal>
</template>

<script>
import { bus } from "../../main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import html2canvas from "html2canvas";
import salesDAO from "../../database/salesContacts";

export default {
  name: "download-pdf",
  components: {
    Loading,
  },
  props: {
    allRequestObj: {
      type: Object,
      URL,
    },
  },
  data() {
    return {
      isLoading: false,
      selRequest: {},
      items: [],
      sales: [],
    };
  },

  async mounted() {
    await this.getContactSales();
  },

  methods: {
    async getContactSales() {
      try {
        this.isLoading = true;
        const salesContact = await salesDAO.retrieveAll();
        this.sales = salesContact.map((sale) => {
          return sale.contactNumber;
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },

    async downloadRequest(evt) {
      evt.preventDefault();
      // Show loader
      this.isLoading = true;

      window.scroll(0, 0);

      let filename = "Request_" + this.selRequest.id + ".png";
      const modalContent = document.getElementById('request-summary');

      const canvas = await html2canvas(modalContent, {
        allowTaint: true,
        scale: 1,
        useCORS: true,
      });

      const image = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = image;
      link.download = filename;
      link.click();

      this.$refs.modal.hide();
      // Hide loader
      this.isLoading = false;
    },

    onReset() {
      this.selRequest = this.$store.getters["contactForm/currRequest"];
      this.items = this.selRequest.items;
    },

    closeModal() {
      this.$refs.modal.hide();
    },
  },

  beforeDestroy() {
    bus.$off("onUpdateSelRequest");
  },
};
</script>

<style scoped>
.quote-brand img {
  width: 200px;
}

.data p span {
  font-weight: 700;
}

fieldset legend {
  width: fit-content;
  font-weight: bold;
  font-size: 21px;
}

.img-border {
  border: 3px solid #eda711;
  border-radius: 15px;
  padding: 10px;
}

.footer {
  color: white;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #2b2b2b;
  margin-top: 20px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.footer-col {
  padding-top: 0.5em;
}

.header-title {
  margin-left: 0em;
  margin-top: -2em;
  padding-top: 1em;
}

.italic-text {
  font-style: italic;
  font-size: 14px;
  color: #eda711;
  margin-left: 2em;
}

legend span {
  display: inline-block;
  border-top: 2px solid #d7d8da;
  margin-left: 10px;
  height: 0.3em;
}

.legend-line {
  width: 12em;
}

.legend-line-project-spec {
  width: 8em;
}
</style>
