import { db, auth } from "../config/firebase";

// Util
import formatUtil from '../utils/formatUtil';

async function getUserByEmail(email) {
  let userObj = {};

  let query = db.collection("users").where("email", "==", email).limit(1);
  const querySnapshot = await query.get();
  querySnapshot.forEach((doc) => {
    userObj = doc.data();
    userObj.id = doc.id;
    userObj.isActive = doc.data().isActive;
  });

  return userObj;
}


async function retrieveAll() {
  let query = db
    .collection("users")
    .orderBy("firstName", "asc")
    .orderBy("lastName", "desc");

  const querySnapshot = await query.get();

  let results = [];
  querySnapshot.forEach((doc) => {
    results.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return results;
}

async function createUser(userObj) {
  let isSuccess = false;

  let email = userObj.email;
  let password = userObj.password;

  let newUserObj = Object.assign({}, userObj);
  delete newUserObj["password"];
  delete newUserObj["confirmPassword"];

  try {
    let result = await db.collection("users").add(newUserObj);
    newUserObj.id = result.id;
    // create corresponding auth user in firebase auth
    await auth.createUserWithEmailAndPassword(email, password);
    isSuccess = true;
  } catch (error) {
    isSuccess = false;
  }

  return {
    isSuccess: isSuccess,
    user: newUserObj,
  };
}

async function updateUser(user) {
  let isSuccess = false;
  try {
    // cleanup before saving
    user = formatUtil.cleanupFields(user);

    await db.collection("users").doc(user.id).set(user);
    isSuccess = true;
  } catch (error) {
    isSuccess = false;
  }
  return {
    isSuccess: isSuccess,
    user: user,
  };
}

export default {
  getUserByEmail,
  retrieveAll,
  createUser,
  updateUser,
};
